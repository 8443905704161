<template>
  <div class="container">
    <div class="indicator-top-container">
      <h3>{{ title }}</h3>
      <Transition>
        <div v-if="$slots.sortSlot && !isMobile" >
          <slot name="sortSlot" />
        </div>
      </Transition>
    </div>
    <div class="indicators-container">
      <div
        v-for="item in 5"
        class="indicator"
        :class="{'active':activeIndex >= item}"
        :key="item"
        :onClick="() => onItemClick(item)" />
    </div>
    <Transition>
      <div style="width: 100%;" v-if="$slots.sortSlot && isMobile" >
        <slot name="sortSlot" />
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import { useWindowSize } from 'vue-window-size';

type IndicatorsByRoute = {
  [key: string]: number;
}

const activeIndicatorsByRoute: IndicatorsByRoute = {
  'index-index': 1,
  'index-index-category': 2,
  'index-index-category-manufacturerId': 3,
  'category-manufacturerId-deviceId-index': 4,
  'category-manufacturerId-deviceId-index-problemId': 5,
}

export default defineComponent({
  name: 'ProgressBar',
  setup() {
    const indicatorStore = useIndicatorStore();
    const { width } = useWindowSize();

    return { indicatorStore, windowWidth: width };
  },
  computed: {
    activeIndex(): number {
      const routeName = this.$route.name ?? 'none';
      if (Object.keys(activeIndicatorsByRoute).includes(routeName.toString())) {
        return activeIndicatorsByRoute[routeName.toString()] as number;
      }
      return 0;
    },
    isMobile(): boolean {
      return this.windowWidth <= 480;
    },
    title() {
      const title = this.indicatorStore.indicatorTitle;

      return title;
    },
  },
  methods: {
    onItemClick(item: number) {
      if (this.activeIndex >= item) {
        const splitPath = this.$route.path.split('/');
        const newPath = splitPath.slice(0, item).join('/');
        if (newPath === '') {
          navigateTo('/');
          return;
        }
        navigateTo(newPath);
      }
    },
  },
});
</script>
<style scoped>
.container {
  display: flex;
  align-items: start;
  flex-direction: column;
}

.indicators-container {
  width: 100%;
  display: flex;
  margin-top: 12px;
  justify-content: space-between;
}

.indicator {
  width: calc((100% - 16px * 4) / 5);
  height: 6px;
  background-color: #DEE1E3;
  border-radius: 20px;
  transition: ease all 500ms;
}

.active {
  background-color: #11335B;
  cursor: pointer;
}

.indicator-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 500ms ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
