<template>
  <div class="container" :class="{ focused: isFocused }">
    <img
      v-if="(!isMobile || forceShowIcon) && prefixIcon === 'location'"
      height="24"
      width="24"
      src="~/assets/location.svg"
      alt="prefix-icon"
    />
    <img
      v-else-if="(!isMobile || forceShowIcon) && prefixIcon === 'search'"
      height="24"
      width="24"
      src="~/assets/search.svg"
      alt="prefix-icon"
    />
    <input
      @keydown.enter.prevent="onEnter"
      @keydown.up.prevent="onArrowUp"
      @keydown.down.prevent="onArrowDown"
      v-model="textValue"
      :placeholder="hint"
      ref="inputRef"
      @focusout="focusOut"
      @focusin="focusIn"
    />
    <img
      :style="{ cursor: 'pointer' }"
      v-show="textValue?.length > 0"
      :onclick="clearData"
      src="../assets/close.svg"
      alt="close"
    />
    <div
      v-if="(textValue?.length > 0 && isFocused) || (dropdownWhenFocused && isFocused)"
      class="dropdown"
    >
      <ul>
        <slot name="dropdownSlot" />
        <li v-if="loadingStatus === 'failed'" class="loader-li failed-loader">
          <img class="empty" width="60" height="52" src="~/assets/empty-folder.svg" alt="empty" />
          <h4 class="failed-title" style="text-align: center">{{ failedTitle }}</h4>
          <h5 class="failed-subtitle">{{ failedDescription }}</h5>
        </li>
        <li v-else-if="loadingStatus === 'loading'" class="loader-li">
          <AppLoader class="loader" />
        </li>
        <li
          v-else
          v-for="(option, index) in dropdownOptions"
          :onmousedown="() => optionClicked(option.value)"
          :key="option.key"
          :style="{ cursor: 'pointer' }"
          :class="{ 'focused-item': focusedItem === index }"
        >
          {{ option.title }}
          <span v-if="option.subtitle">{{ option.subtitle }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { useWindowSize } from 'vue-window-size';

export interface SearchBarRefType {
  setValue: (value: string) => void;
}

export default defineComponent({
  name: 'SearchBar',
  props: {
    prefixIcon: {
      default: 'search',
      type: String as PropType<SearchPrefixIcon>,
    },
    hint: {
      default: '',
      type: String,
    },
    dropdownOptions: {
      default: () => [],
      type: Array as PropType<DropDownOptionProps[]>,
    },
    loadingStatus: {
      default: 'idle',
      type: String as PropType<LoadingType>,
    },
    forceShowIcon: {
      default: false,
    },
    failedTitle: {
      default: 'Nie znaleziono żadnych wyników.',
      type: String,
    },
    failedDescription: {
      default: 'Spróbuj ponownie lub skorzystaj z dostępnej poniżej listy kategorii.',
      type: String,
    },
    dropdownWhenFocused: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    isMobile(): boolean {
      return this.windowWidth <= 480;
    },
    isTablet(): boolean {
      return this.windowWidth >= 481 && this.windowWidth <= 768;
    },
    isDesktop(): boolean {
      return !this.isMobile && !this.isTablet;
    },
  },
  data() {
    return {
      textValue: '' as string,
      isFocused: false as boolean,
      focusedItem: -1 as number,
    };
  },
  watch: {
    textValue(newValue) {
      this.valueChanged(newValue);
    },
  },
  setup() {
    const { width } = useWindowSize();
    const inputRef = ref<HTMLInputElement>();
    return {
      windowWidth: width,
      inputRef,
    };
  },
  methods: {
    clearData() {
      this.textValue = '';
      this.inputRef?.focus();
      this.$emit('clearData');
    },
    valueChanged(newValue: string) {
      this.focusedItem = -1;
      this.$emit('valueChanged', newValue);
    },
    optionClicked(value: unknown) {
      this.$emit('optionClicked', value);
      (this.$refs.inputRef as HTMLInputElement).blur();
    },
    focusOut() {
      this.focusedItem = -1;
      this.isFocused = false;
    },
    focusIn() {
      this.isFocused = true;
    },
    setValue(value: string) {
      this.textValue = value;
    },
    onArrowUp() {
      if (this.loadingStatus === 'idle' && this.dropdownOptions.length > 0) {
        if (this.focusedItem === 0) {
          return;
        }

        this.focusedItem -= 1;
      }
    },
    onArrowDown() {
      if (this.loadingStatus === 'idle' && this.dropdownOptions.length > 0) {
        if (this.focusedItem < this.dropdownOptions.length - 1) {
          this.focusedItem += 1;
        }
      }
    },
    onEnter() {
      if (this.loadingStatus === 'idle' && this.dropdownOptions.length > 0) {
        this.optionClicked(
          this.dropdownOptions[this.focusedItem !== -1 ? this.focusedItem : 0].value,
        );
        this.isFocused = false;
      }
    },
  },
});
</script>
<style scoped>
.container {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 16px;
  background-color: #fff;
  box-shadow: 2px 6px 16px 0px rgba(13, 31, 77, 0.06);
  border-radius: 8px;
  position: relative;
  z-index: 3;
}

input {
  border: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin-left: 8px;
}

input:focus {
  outline: none;
}

.dropdown {
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 100%;
}

ul {
  width: 100%;
  text-align: start;
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-size: 16px;
  position: absolute;
  z-index: 1;
  background-color: #fff;
  color: #111528;
  border-radius: 8px;
  box-shadow: 4px 24px 40px 0px #8197a752;
}

li {
  font-family: 'Clarity-City';
  padding: 16px;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  transition: ease all 300ms;
  border-radius: 8px;
}

li:hover,
.focused-item {
  background-color: #f3f6f6;
}

span {
  color: #798491;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Clarity-City';
  padding-top: 12px;
}

.loader-li:hover {
  background-color: transparent;
}

.loader-li {
  height: 160px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.failed-loader {
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.empty {
  margin-bottom: 24px;
}

.failed-title {
  color: #111528;
}

.failed-subtitle {
  color: #798491;
}

.loader {
  transform: scale(0.6);
}

.focused {
  border-bottom: 2px solid #11335b;
  padding-bottom: 14px;
}

@media screen and (max-width: 480px) {
  .container {
    height: 24px;
  }

  .failed-loader {
    padding: 40px;
  }
}
</style>
